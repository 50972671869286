var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品牌名:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.logo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "logo", $$v)
                  },
                  expression: "formData.logo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }